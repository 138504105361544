import ProductInfo from "../components/productInfo";
import ProductsSlider from "../components/productsSlider";
import { useEffect, useState } from "react";
import Spinner from "../components/spinner";
import EmptyBlock from "../components/emptyBlock";
import { useParams } from "react-router-dom";
import ProductController from "../controllers/productController";
import { scrollToTop } from "../utils/scroll";
import { Helmet } from "react-helmet";
import { percentFormate } from "../utils/percent";
import { priceFormate } from "../utils/price";
const API_URL = "https://vetervremeni.kz/api/";

const ProductPage = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(null);
	const { id } = useParams();

	const fetchData = async () => {
		setLoading(true);
		const response = await ProductController.getOne(id);
		const { data, success } = response;

		if (success) {
			setData(data);
			console.log("Product: ", data);
		}

		setLoading(false);
	};

	useEffect(() => {
		fetchData();
		scrollToTop();
	}, [id]);

	const productDescription = data
		? Object.entries(data.attributes)
				.map(([key, attr]) => ({
					title: attr.title,
					value: attr.value,
				}))
				.map((item) => `${item.title} - ${item.value}`)
				.join(" • ")
		: "";

	if (loading) {
		return <Spinner padding={true} />;
	} else if (!data) {
		return <EmptyBlock icon="error" text="Товар не найден" padding={true} />;
	} else {
		const jsonLd = {
			"@context": "http://schema.org/",
			"@type": "Product",
			name: data.name,
			image: API_URL + data.image,
			description: productDescription,
			url: window.location.href,
			offers: {
				"@type": "Offer",
				priceCurrency: "₸",
				price: data.price ? data.price : 0,
				availability: "Есть в наличии",
				seller: {
					"@type": "Organization",
					name: "G-Square",
				},
			},
			aggregateRating: {
				"@type": "AggregateRating",
				ratingValue: "4.5",
				reviewCount: "24",
			},
			review: [
				{
					"@type": "Review",
					reviewRating: {
						"@type": "Rating",
						ratingValue: "5",
					},
					author: {
						"@type": "Person",
						name: "Алибек Ельшатов",
					},
					reviewBody: "Отличный продукт! Очень доволен покупкой.",
				},
				{
					"@type": "Review",
					reviewRating: {
						"@type": "Rating",
						ratingValue: "4.7",
					},
					author: {
						"@type": "Person",
						name: "Иван Смирнов",
					},
					reviewBody: "Хороший продукт",
				},
				{
					"@type": "Review",
					reviewRating: {
						"@type": "Rating",
						ratingValue: "5",
					},
					author: {
						"@type": "Person",
						name: "Ансар Аймаков",
					},
					reviewBody: "Отличный продукт! Очень доволен покупкой.",
				},
				{
					"@type": "Review",
					reviewRating: {
						"@type": "Rating",
						ratingValue: "5",
					},
					author: {
						"@type": "Person",
						name: "Маржан Байзаков",
					},
					reviewBody: "Отличные часы.Очень качественные",
				},
			],
		};

		return (
			<>
				<Helmet>
					<title>
						Купить часы {data.name} в Алматы и Астане, Казахстан. 🕒 Высокое
						качество и доступные цены.
					</title>
					<meta
						property="title"
						content={`Купить часы ${data.name} в Алматы и Астане, Казахстан. 🕒 Высокое качество и доступные цены.`}
					/>
					<meta name="description" content={productDescription} />
					<meta
						name="keywords"
						content={`часы CASIO, купить часы в Алматы, купить часы в Астане, купить часы в Казахстане, ${data.name}`}
					/>
					<meta name="author" content="G-Square" />
					<link rel="canonical" href={window.location.href} />

					{/* Open Graph / Facebook */}
					<meta property="og:type" content="product" />
					<meta
						property="og:title"
						content={`Купить часы ${data.name} в Алматы и Астане, Казахстан. 🕒 Высокое качество и доступные цены.`}
					/>
					<meta property="og:description" content={productDescription} />
					<meta property="og:image" content={API_URL + data.image} />
					<meta property="og:url" content={window.location.href} />
					<meta property="og:site_name" content="G-Square" />

					{/* Twitter */}
					<meta name="twitter:card" content="summary_large_image" />
					<meta
						name="twitter:title"
						content={`Купить часы ${data.name} в Алматы и Астане, Казахстан. 🕒 Высокое качество и доступные цены.`}
					/>
					<meta name="twitter:description" content={`${productDescription}`} />
					<meta name="twitter:image" content={API_URL + data.image} />
					<meta name="twitter:site" content="@G-Square" />

					{/* // Микро разметка JSON-LD */}
					<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
				</Helmet>

				<ProductInfo data={data} />

				<ProductsSlider
					params={{
						filters: data.brand
							? { [data.brand.filterId]: [data.brand.valueId] }
							: null,
						exception: [data.id],
						rand: true,
						withImage: false,
						available: true,
					}}
					title="Также рекомендуем"
				/>
			</>
		);
	}
};

export default ProductPage;
