import c from "./styles.module.scss";
import { NavLink } from "react-router-dom";
import Container from "../container";
import { useState } from "react";
import image1 from "../../assets/images/man.webp";
import image2 from "../../assets/images/woman.webp";
import More from "../UI/more";

const Card = ({ data }) => {
  const { link, title, text, image } = data;

  return (
    <NavLink to={link} end className={c.card}>
      <div className={[c.cardInner, "dark"].join(" ")}>
        <div className={c.image}>
          <img src={image} alt={title} loading="lazy" />
        </div>
        <div className={c.content}>
          {title ? <div className={c.title}>{title}</div> : null}
          {text ? <div className={c.text}>{text}</div> : null}
          <More text="Смотреть" />
        </div>
      </div>
    </NavLink>
  );
};

const Categories = () => {
  const [data] = useState([
    {
      link: "/products?filters=470294500%3A1556981024%2C380812251",
      title: "Мужские часы",
      text: "Новая лимитированная коллеция",
      image: image1,
    },
    {
      link: "/products?filters=470294500%3A380812251%2C1331059330",
      title: "Женские часы",
      text: "Новая лимитированная коллеция",
      image: image2,
    },
  ]);

  return (
    <section className={c.section}>
      <Container>
        <div className={c.inner}>
          <div className={c.description}>
            <div className={c.text}>
              Мужские часы Casio представляют собой совершенное сочетание стиля,
              технологии и прочности. Женские часы Casio сочетают в себе
              женственность, стиль и функциональность.
            </div>
            <More link="products" text="В каталог"  />
          </div>
          {data.map((item, index) => (
            <Card key={index} data={item} />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Categories;
