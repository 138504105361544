import { Helmet } from "react-helmet-async";
import baby_g_mobile from "../../assets/images/banners/baby_g_mobile.png";
import g_shock_mobile from "../../assets/images/banners/g_shock_mobile.png";
import edifice_mobile from "../../assets/images/banners/edifice_mobile.png";
import pro_trek_mobile from "../../assets/images/banners/pro_trek_mobile.png";
import sheen_mobile from "../../assets/images/banners/sheen_mobile.png";
import general_mobile from "../../assets/images/banners/general_mobile.png";
import man from "../../assets/images/man.webp";
import woman from "../../assets/images/woman.webp";
import main from "../../assets/images/about.webp";

const MetaHelmet = ({ option }) => {
  const jsonLd = {
		"@context": "https://schema.org/",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Главная",
				item: "https://www.g-factory.kz/",
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Каталог",
				item: "https://www.g-factory.kz/products",
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "Мужские Часы",
				item: "https://www.g-factory.kz/products?filters=470294500%3A1556981024%2C380812251",
			},
			{
				"@type": "ListItem",
				position: 4,
				name: "Женские часы",
				item: "https://www.g-factory.kz/products?filters=470294500%3A380812251%2C1331059330",
			},

			{
				"@type": "ListItem",
				position: 5,
				name: "G-SHOCK",
				item: "https://www.g-factory.kz/products?filters=1123632576%3A1655608949",
			},
			{
				"@type": "ListItem",
				position: 6,
				name: "BABY-G",
				item: "https://www.g-factory.kz/products?filters=1123632576%3A1244322860",
			},

			{
				"@type": "ListItem",
				position: 7,
				name: "EDIFICE",
				item: "https://www.g-factory.kz/products?filters=1123632576%3A919493840",
			},
			{
				"@type": "ListItem",
				position: 8,
				name: "PRO TREK",
				item: "https://www.g-factory.kz/products?filters=1123632576%3A503817821",
			},
			{
				"@type": "ListItem",
				position: 9,
				name: "SHEEN",
				item: "https://www.g-factory.kz/products?filters=1123632576%3A391419574",
			},
		],
	};
  let metaData = {
    title: "",
    description: "",
    type: "",
    name: "",
    imageUrl: "",
  };

  	switch (option) {
			case "main":
				metaData = {
					title: "Купить Часы CASIO в Казахстане, G-Shock в Алматы и Астане",
					description:
						"Самый большой выбор CASIO и G-Shock в Казахстане. Низкие цены, высокое качество и быстрая доставка!",
					type: "website",
					imageUrl: main,
				};
				break;
			case "catalog":
				metaData = {
					title: "Купить Часы CASIO в Казахстане в Алматы и регионах - Каталог",
					description:
						"Очень много часов CASIO для мужчин и женщин в нашем каталоге. Выбирайте и заказывайте прямо сейчас!",
					type: "website",
					imageUrl: general_mobile,
				};
				break;
			case "menWatches":
				metaData = {
					title:
						"Купить Мужские Часы CASIO в Казахстане, Мужские Casio в Алматы и Атырау",
					description:
						"Модели для спорта, активного отдыха и повседневной жизни с передовыми технологиями. Выберите свой G-Shock уже сегодня!",
					type: "website",
					imageUrl: man,
				};
				break;
			case "womenWatches":
				metaData = {
					title:
						"Купить Женские Часы CASIO в Казахстане, Женские Casio в Алматы и Караганде",
					description:
						"Casio женские – гармония красоты и надёжности. Современные модели, будь то офис или вечерний выход.",
					type: "website",
					imageUrl: woman,
				};
				break;
			case "sheen":
				metaData = {
					title: "Купить Часы Sheen в Алматы и Актау",
					description:
						"Изящный дизайн и камни Swarovski для каждой современной женщины. Найдите свой идеальный Sheen!",
					type: "website",
					imageUrl: sheen_mobile,
				};
				break;
			case "g-shock":
				metaData = {
					title: "Купить Часы G-Shock в Алматы и Астане",
					description:
						"Высокая водостойкость, прочность, солнечные батареи и много функций - в нашем каталоге!",
					type: "website",
					imageUrl: g_shock_mobile,
				};
				break;
			case "general":
				metaData = {
					title: "Купить Часы CASIO в Казахстане, Casio в Алматы и Астане",
					description:
						"Официальные поставки CASIO и G-Shock в Казахстане. Низкие цены, оригинал и быстрая доставка.",
					type: "website",
					imageUrl: general_mobile,
				};
				break;
			case "edifice":
				metaData = {
					title: "Купить Часы Edifice в Казахстане в Алматы и Шымкенте",
					description:
						"Стильные, спортивные и функциональные часы Edifice для мужчин. Выбирайте стиль и оригинал!",
					type: "website",
					imageUrl: edifice_mobile,
				};
				break;
			case "pro-trek":
				metaData = {
					title: "Купить Часы ProTrek в Казахстане",
					description:
						"Надежные часы ProTrek для любителей горного отдыха. Функции GMT и компас - готовьтесь к новым приключениям!",
					type: "website",
					imageUrl: pro_trek_mobile,
				};
				break;
			case "baby-g":
				metaData = {
					title: "Купить Часы Baby-G в Алматы и Астане",
					description:
						"Яркие и стильные часы Baby-G для энергичных и стильных. Пусть часы подстраиваются под ваш образ!",
					type: "website",
					imageUrl: baby_g_mobile,
				};
				break;
			default:
				metaData = {
					title: "Купить Часы CASIO в Казахстане",
					description:
						"Широкий выбор часов CASIO и G-Shock в Казахстане. Доступные цены, высокое качество и быстрая доставка.",
					type: "website",
					imageUrl: main,
				};
		}


  return (
		<Helmet>
			{/* Standard metadata tags */}
			<title>{metaData.title}</title>
			<meta name="description" content={metaData.description} />
			{/* Facebook tags */}
			<meta property="og:type" content={metaData.type} />
			<meta property="og:title" content={metaData.title} />
			<meta property="og:description" content={metaData.description} />
			<meta property="og:image" content={metaData.imageUrl} />
			{/* Twitter tags */}
			<meta name="twitter:creator" content={metaData.name} />
			<meta name="twitter:card" content={metaData.type} />
			<meta name="twitter:title" content={metaData.title} />
			<meta name="twitter:description" content={metaData.description} />
			<meta name="twitter:image" content={metaData.imageUrl} />
			{/* // Микро разметка JSON-LD */}
			<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
		</Helmet>
	);
};

export default MetaHelmet;
