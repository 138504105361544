const MobileNav = [
	{
		icon: "home",
		url: "/",
		text: "Главная",
		type: "text",
	},
	{
		icon: "inbox",
		url: "/products",
		text: "Каталог",
		type: "text",
	},
	{
		icon: "arrow-right",
		url: "/products?filters=470294500%3A1556981024%2C380812251",
		text: "Мужские часы",
		type: "text",
	},
	{
		icon: "arrow-right",
		url: "/products?filters=470294500%3A380812251%2C1331059330",
		text: "Женские часы",
		type: "text",
	},
	{
		icon: "arrow-right",
		url: "/products?filters=1123632576%3A1673978871",
		text: "GENERAL",
		type: "text",
	},
	{
		icon: "arrow-right",
		url: "/products?filters=1123632576%3A1655608949",
		path: "/g-shock.svg",
		type: "svg",
	},
	{
		icon: "arrow-right",
		url: "/products?filters=1123632576%3A1244322860",
		path: "/baby-g.svg",
		type: "svg",
	},
	{
		icon: "arrow-right",
		url: "/products?filters=1123632576%3A919493840",
		path: "/edifice.svg",
		type: "svg",
	},
	{
		icon: "arrow-right",
		url: "/products?filters=1123632576%3A503817821",
		path: "/protrek.svg",
		type: "svg",
	},
	{
		icon: "arrow-right",
		url: "/products?filters=1123632576%3A265688798",
		path: "/sheen.svg",
		type: "svg",
	},
	{
		icon: "box",
		url: "/delivery",
		text: "Доставка",
		type: "text",
	},
	{
		icon: "option",
		url: "/service",
		text: "Сервис центр",
		type: "text",
	},
	{
		icon: "help",
		url: "/faq",
		text: "FAQ",
		type: "text",
	},
	{
		icon: "phone",
		url: "/contact",
		text: "Контакты",
		type: "text",
	},
];

export default MobileNav;
