import MainSlider from "../components/mainSlider";
import AdvantagesRow from "../components/advantagesRow";
import Categories from "../components/categories";
import ProductsSlider from "../components/productsSlider";
import About from "../components/about";
import MetaHelmet from "../components/meta/metaHelmet.js";
import ContactsForHomePage from "../components/contactsForHomePage";
import { popularModels } from "../popularModelsArray.js";
import AdditionalText from "../components/additionalText/index.js";
import { Helmet } from "react-helmet-async";
import PostSlider from "../components/postSlider/";
import PartnersBlock from "../components/partnersBlock/index.js";
const HomePage = () => {
  return (
    <>
      <MetaHelmet option={"main"} />
      <MainSlider />
      <ProductsSlider title="Рекомендуем посмотреть" />
      <AdvantagesRow />
      <PostSlider />
      <PartnersBlock />
      <Categories />
      <ProductsSlider title="Хиты продаж" params={{ ids: popularModels }} />
      <ContactsForHomePage />
      <About />
      <AdditionalText />
    </>
  );
};

export default HomePage;
