import PageHeader from "../components/pageHeader";
import ProductsArchive from "../components/productsArchive";
import MetaHelmet from "../components/meta/metaHelmet";
import {FilterToKeyMap} from "../routes/bottomHeaderMenu";
import { useLocation } from "react-router-dom";
const ProductsPage = () => {
	
	const location = useLocation();

	const searchParams = new URLSearchParams(location.search);
	const filters = searchParams.get("filters");

	let optionName = FilterToKeyMap[filters] || "catalog";

	
	return (
		<>
			<MetaHelmet option={optionName} />
			<PageHeader
				title="Каталог"
				breadcrumbs={[
					{
						link: "/products",
						text: "Каталог",
					},
				]}
			/>
			<ProductsArchive optionName={optionName} />
		</>
	);
};

export default ProductsPage;
