import React from "react";
import c from "./styles.module.scss";
import Container from "../container";
import { PARTNERS } from "../../config";
import wo from "../../assets/images/partners/wo.svg";
import st from "../../assets/images/partners/st.svg";
import vv from "../../assets/images/partners/vv.svg";

const images = { wo, st, vv };

const PartnersBlock = () => {
  return (
    <section className={c.partners}>
      <Container>
        <div className="h2">Наши партнеры</div>
        <div className={c.partnersInner}>
          {PARTNERS.map((partner) => (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={partner.href}
              key={partner.id}
              className={c.partnerCard}
            >
              <img
                src={images[partner.key] || partner.key}
                alt={partner.name}
                width={200}
                height={200}
              />
            
            </a>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default PartnersBlock;
