export const mainText = `
<div>
  <h1>Купить Casio в Алматы, Астане, Караганде, Шымкенте, Атырау и Актау</h1>
  
  <p>Если вы хотите купить оригинальные часы Casio, вы обратились по правильному адресу. Casio – это бренд, который заслужил мировую известность благодаря инновационному дизайну, высокой точности и передовым технологиям в производстве наручных часов. В нашем ассортименте представлены только оригинальные модели Casio, предназначенные для любых потребностей и различных стилей жизни. Независимо от того, ищете ли вы спортивные, классические или высокотехнологичные часы, у нас вы обязательно найдете подходящий вариант.</p>

  <h2>Мужские часы Casio</h2>
  <p>Для мужчин Casio предлагает широкий выбор моделей, которые идеально подойдут как для делового стиля, так и для повседневного использования. От элегантных классических дизайнов до спортивных и функциональных моделей – каждые мужские часы Casio сочетают в себе безупречное качество, надежность и инженерную точность. Водонепроницаемость, ударопрочность, хронограф и другие функциональные возможности обеспечивают комфорт и уверенность при ношении в любых условиях.</p>

  <h2>Женские часы Casio</h2>
  <p>Женские часы Casio отличаются разнообразием дизайнов, которые подчеркивают элегантность, утонченность и индивидуальность каждой обладательницы. Изящные формы, утонченные линии, благородная отделка – такие аксессуары станут идеальным дополнением как к повседневному, так и к вечернему образу. В коллекциях представлены модели как для активного образа жизни, так и для особых случаев – каждая женщина найдет часы Casio, соответствующие ее стилю.</p>

  <h2>Оригинальные часы Casio</h2>
  <p>В нашем интернет-магазине представлены самые популярные коллекции Casio, каждая из которых сочетает в себе передовые технологии, современный дизайн и надежность. Вы можете выбрать модели из следующих линеек:</p>
  <ul>
    <li><strong>Casio G-Shock</strong> – часы с повышенной ударопрочностью, защитой от грязи и воды, идеально подходящие для активных людей и экстремальных видов спорта.</li>
    <li><strong>Casio Edifice</strong> – стильные хронографы с функцией мирового времени, солнечной батареей и другими возможностями, идеально вписывающиеся в деловой стиль.</li>
    <li><strong>Casio Pro Trek</strong> – модели для любителей активного отдыха и путешествий, оснащенные высотомером, барометром, компасом и другими полезными функциями.</li>
    <li><strong>Casio Sheen</strong> – женская коллекция, отличающаяся утонченным дизайном, использованием высококачественных материалов и кристаллов Swarovski.</li>
  </ul>

  <h2>Оригинальные часы G-Shock</h2>
  <p>Часы G-Shock – одна из самых популярных коллекций Casio, разработанная специально для людей, ведущих активный образ жизни. Уникальная система защиты от ударов, вибраций, влаги и пыли делает эти модели идеальными для экстремальных условий. Они подойдут спортсменам, путешественникам и тем, кто ценит прочность и надежность в любых ситуациях.</p>

  <h2>Спортивные часы Edifice</h2>
  <p>Модели Casio Edifice – это сочетание элегантности и современных технологий. Эти часы станут отличным выбором для деловых встреч, офиса и повседневного ношения. Хронографы, функция мирового времени, солнечная батарея и другие характеристики гарантируют точность и комфорт в эксплуатации.</p>

  <h2>Часы для активного вида спорта – ProTrek</h2>
  <p>Часы Casio ProTrek предназначены для любителей приключений и активного отдыха. Они оснащены множеством полезных функций: высотомер, барометр, компас, термометр и подсветка. Эти модели станут надежным спутником в походах, на рыбалке и в горах, обеспечивая максимальное удобство и прочность.</p>

  <h2>Женские часы Sheen</h2>
  <p>Коллекция Casio Sheen создана специально для женщин, которые ценят элегантность, стиль и качество. Эти часы выполнены из высококачественных материалов, украшены кристаллами Swarovski и станут прекрасным дополнением к любому образу. Они подчеркнут женственность и добавят нотку роскоши в повседневный стиль.</p>

  <h2>Купить Casio (Касио) в Казахстане с доставкой в любой город!</h2>
  <p>Наш интернет-магазин предлагает оригинальные часы Casio по доступным ценам с доставкой по всему Казахстану. Вы легко сможете оформить заказ всего за несколько кликов. Удобная навигация по сайту позволяет быстро найти нужную модель, добавить её в корзину и оформить покупку.</p>

  <p>
  Мы гарантируем подлинность всей продукции, предоставляем международную гарантию, безопасность платежей и быструю доставку в Алматы, Астану, Караганду, Шымкент, Атырау и Актау.
  <br/>
Выбирайте надежность, стиль и японское качество – заказывайте оригинальные часы Casio прямо сейчас!
</p>
</div>
`;
