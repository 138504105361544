import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import c from "./styles.module.scss";
import Container from "../container";
import ArchiveFilters from "../archiveFilters";
import Select from "../UI/select";
import Switcher from "../UI/switcher";
import { quantityFormate } from "../../utils/quantity";
import Product from "../product";
import ProductController from "../../controllers/productController";
import Spinner from "../spinner";
import EmptyBlock from "../emptyBlock";
import { filtersString } from "../../utils/filters";
import Pagination from "../pagination";

const ProductsArchive = ({ optionName }) => {
  const url = new URL(window.location.href);
  const min = url.searchParams.get("min");
  const max = url.searchParams.get("max");
  const filters = url.searchParams.get("filters");
  const navigate = useNavigate();

  const [ids, setIds] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(parseInt(url.searchParams.get("page")) || 1);
  const [pages, setPages] = useState(1);
  const [limits] = useState([
    {
      key: "12",
      text: quantityFormate(12),
    },
    {
      key: "24",
      text: quantityFormate(24),
    },
    {
      key: "48",
      text: quantityFormate(48),
    },
  ]);
  const [limit, setLimit] = useState(localStorage.getItem("gf_limit") || limits[0].key);
  const [sort, setSort] = useState(localStorage.getItem("gf_sort") || "news");
  const [sortItems] = useState([
    {
      value: "news",
      text: "По новинкам",
    },
    {
      value: "priceasc",
      text: "Цена: по возрастанию",
    },
    {
      value: "pricedesc",
      text: "Цена: по убыванию",
    },
  ]);

  const fetchData = async (page = 1) => {
    let sortOptions = [["id", "desc"]];
    const clearedFilters = filtersString(filters);

    if (sort === "priceasc") {
      sortOptions = [["price", "asc"]];
    } else if (sort === "pricedesc") {
      sortOptions = [["price", "desc"]];
    } else if (sort === "news") {
      sortOptions = [["id", "desc"]];
    }

    const fetchParams = {
      filters: clearedFilters,
      limit,
      page,
      sort: sortOptions,
      available: true,
    };
    
   

    if (min) {
      fetchParams.min = min;
    }

    if (max) {
      fetchParams.max = max;
    }

    const getProducts = await ProductController.getAll(fetchParams);
    const { rows, pages, count, ids } = getProducts.data;
    const data = { rows, pages, count, ids };
    console.log(data)
    return data;
  };

  const newFetch = async () => {
    setLoading(true);
    setBtnLoading(true);
    const data = await fetchData(page);
    const { rows, pages, count, ids } = data;
    setProducts(rows);
    setIds(ids);
    setCount(count);
    setPages(pages);
    setLoading(false);
    setBtnLoading(false);
  };

  useEffect(() => {
    newFetch();
  }, [sort, limit, min, max, filters, page]);

  useEffect(() => {
    sessionStorage.setItem("gf_page", page);
  }, [page]);

  useEffect(() => {
    const savedPage = sessionStorage.getItem("gf_page");
    if (savedPage) {
      setPage(parseInt(savedPage));
    //   sessionStorage.removeItem("gf_page");
    } else {
      setPage(1);
    }
  }, [optionName]);



  const onPreviousPage = async () => {
    if (page > 1) {
      const newPage = page - 1;
      setPage(newPage);
      updatePageInUrl(newPage);
      scrollToTop();
    }
  };

  const onNextPage = async () => {
    if (page < pages) {
      const newPage = page + 1;
      setPage(newPage);
      updatePageInUrl(newPage);
      scrollToTop();
    }
  };

  const onChoosePage = async (choosenPage) => {
    setPage(choosenPage);
    updatePageInUrl(choosenPage);
    scrollToTop();
  };

  const updatePageInUrl = (newPage) => {
    const searchParams = new URLSearchParams(url.search);
    searchParams.set("page", newPage);
    navigate(`${url.pathname}?${searchParams.toString()}`);
  };

  const onProductClick = (productId) => {
    sessionStorage.setItem("currentPage", page);
    window.location.href = `/product/${productId}`;
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <section className={c.section}>
      <Container>
        <div className={c.inner}>
          <div className={c.sidebar}>
            <ArchiveFilters min={min} max={max} filters={filters} ids={ids} />
          </div>
          <div className={c.body}>
            <div className={c.top}>
              <div className={c.count}>Найдено: {quantityFormate(count)}</div>
              <div className={c.right}>
                <div className={c.quantitySwitcher}>
                  <Switcher
                    value={limit}
                    items={limits}
                    size="smaller"
                    onChange={(value) => {
                      localStorage.setItem("gf_limit", value);
                      setLimit(value);
                    }}
                  />
                </div>
                <div className={c.select}>
                  <Select
                    value={sort}
                    onChange={(value) => {
                      localStorage.setItem("gf_sort", value);
                      setSort(value);
                    }}
                    size="smaller"
                    items={sortItems}
                  />
                </div>
              </div>
            </div>
            {loading ? (
              <Spinner />
            ) : (
              <>
                {products.length ? (
                  <>
                    <div className={c.grid}>
                      {products
                        .filter((item) => item.quantity > 0)
                        .map((item) => (
                          <Product key={item.id} data={item} onClick={() => onProductClick(item.id)} />
                        ))}
                    </div>
                    {count > Number(limit) && (
                      <Pagination
                        onPreviousPage={onPreviousPage}
                        onChoosePage={onChoosePage}
                        onNextPage={onNextPage}
                        from={1}
                        to={pages}
                        active={page}
                      />
                    )}
                  </>
                ) : (
                  <EmptyBlock icon="inbox" text="Ничего не найдено" />
                )}
              </>
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ProductsArchive;
