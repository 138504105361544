const BottomHeaderMenu = [
	{
		url: "/",
		text: "Главная",
		type: "text",
		key: "home",
	},
	{
		url: "/products",
		text: "Каталог",
		type: "text",
		key: "catalog",
	},
	{
		url: "/products?filters=470294500%3A1556981024%2C380812251",
		text: "Мужские часы",
		type: "text",
		key: "menWatches",
	},
	{
		url: "/products?filters=470294500%3A380812251%2C1331059330",
		text: "Женские часы",
		type: "text",
		key: "womenWatches",
	},
	{
		url: "/products?filters=1123632576%3A1673978871",
		text: "GENERAL",
		type: "text",
		key: "key",
	},
	{
		url: "/products?filters=1123632576%3A1655608949",
		path: "/g-shock.svg",
		type: "svg",
		key: "g-shock",
	},
	{
		url: "/products?filters=1123632576%3A1244322860",
		path: "/baby-g.svg",
		type: "svg",
		key: "baby-g",
	},
	{
		url: "/products?filters=1123632576%3A919493840",
		path: "/edifice.svg",
		type: "svg",
		key: "edifice",
	},
	{
		url: "/products?filters=1123632576%3A503817821",
		path: "/protrek.svg",
		type: "svg",
		key: "protrek",
	},
	{
		url: "/products?filters=1123632576%3A265688798",
		path: "/sheen.svg",
		type: "svg",
		key: "sheen",
	},
];

const FilterToKeyMap = {
	"470294500:1556981024,380812251": "menWatches",
	"470294500:380812251,1331059330": "womenWatches",
	"1123632576:1673978871": "general",
	"1123632576:1655608949": "g-shock",
	"1123632576:1244322860": "baby-g",
	"1123632576:919493840": "edifice",
	"1123632576:503817821": "pro-trek",
	"1123632576:265688798": "sheen",
};


export  {BottomHeaderMenu, FilterToKeyMap };


