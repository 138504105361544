import { useState, useMemo, useEffect } from "react";
import c from "./styles.module.scss";
import CartParamsList from "../cartParamsList";
import { priceFormate } from "../../utils/price";
import { quantityFormate } from "../../utils/quantity";
import Button from "../UI/button";
import $api from "../../http";
import { NavLink } from "react-router-dom";
import { INFO_LINKS } from "../../config";
import { useSelector } from "react-redux";
import { dispatchStore } from "../../store";
import CartController from "../../controllers/cartController";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {
	setFormErrorAction,
	setFormErrorsAction,
	setFormIsCorrectAction,
} from "../../store/cartReducer";

const CartSidebar = ({ onClick }) => {
	const [links] = useState(INFO_LINKS);
	const {
		totalQuantity,
		totalPrice,
		purePrice,
		added,
		products,
		totalOldPrice,
		overallDiscount,
	} = useSelector((store) => store.cart);
	const { isAuth, user } = useSelector((store) => store.auth);
	const { formData, formErrors, formError } = useSelector(
		(store) => store.cart
	);

	const buyInInstallment = async () => {
		if (
			!formErrors.name &&
			!formErrors.phone &&
			!formErrors.agree &&
			!formErrors.address &&
			formData.name &&
			formData.phone &&
			formData.address &&
			formData.agree
		) {
			const data = { ...formData, added };
			data.phone = data.phone.replaceAll(/[^0-9]/gm, "");
			data.comment = `Заказ через рассрочку BCC (BNPL), Комментарий клиента: ${data.comment}`;

			if (isAuth) {
				data.id = user.id;
			}

			toast.info("Сейчас откроется форма для оформления рассрочки", {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
			});

			const send = await CartController.send(data);

			if (send.success) {
				localStorage.setItem("orderName", formData.name);
				localStorage.setItem("orderPhone", formData.phone);
				localStorage.setItem("orderAddress", formData.address);

				const productIdsWithQuantities = added;
				const allProducts = productIdsWithQuantities.map((product) => ({
					id: String(product.id),
					name: products[Number(product.id)].name,
					price: Math.round(products[Number(product.id)].price),
					quantity: product.quantity,
				}));

				const redirectData = await $api.post("/api/bnpl/redirectLink", {
					billAmount: String(Math.round(totalPrice)),
					itemsQuantity: totalQuantity,
					products: allProducts,
					phoneNumber: formData.phone,
				});

				window.location.replace(redirectData.data.redirectLink);
			} else {
				dispatchStore(setFormErrorAction(send.message));
			}
		} else {
			if (!formData.agree) {
				toast.error(
					"Вы не заполнили обязательные поля для оформления рассрочки",
					{
						position: toast.POSITION.BOTTOM_RIGHT,
						autoClose: 2000,
					}
				);

				dispatchStore(setFormErrorsAction({ ...formErrors, agree: true }));
				dispatchStore(setFormIsCorrectAction(false));
			}
		}
	};

  const cartParams = useMemo(() => {
		return [
			{ title: "Количество:", value: quantityFormate(totalQuantity) },
			{ title: "Товаров на:", value: priceFormate(Math.round(purePrice)) },
			...(totalOldPrice !== totalPrice
				? [
						{
							title: "Общая цена без скидки:",
							value: priceFormate(totalOldPrice),
						},
						{ title: "Общая скидка:", value: `${overallDiscount} %` },
				  ]
				: []),
			{
				title: "Итого:",
				value: priceFormate(Math.round(totalPrice)),
				large: true,
			},
		];
	}, [totalQuantity, purePrice, totalOldPrice, totalPrice, overallDiscount]);


	return (
		<>
			<div className={c.sidebar}>
				<div className={c.row}>
					<div className={[c.title, "h6"].join(" ")}>Моя корзина</div>
					<div className={c.params}>
						<CartParamsList
							items={[...cartParams]}
						/>
					</div>
					<div className={c.btns}>
						<Button
							text="Оформить заказ"
							full={true}
							onClick={onClick}
							disabled={!totalQuantity}
						/>
						{totalQuantity > 0 && (
							<button className={c.installment} onClick={buyInInstallment}>
								Оплатить в рассрочку 0-0-4
							</button>
						)}
						<Button
							text="Очистить корзину"
							full={true}
							btnStyle="grey"
							disabled={!totalQuantity}
							onClick={CartController.clearCart}
						/>
					</div>
				</div>
				<div className={c.row}>
					<div className={c.smallTitle}>Информация:</div>
					<div className={c.links}>
						{links.map((item, i) => {
							const { icon, link, text } = item;

							return (
								<NavLink key={i} to={link} end className={c.link}>
									<div className={[c.icon, "icon-" + icon].join(" ")}></div>
									<div className={c.text}>{text}</div>
									<div className={[c.arrow, "icon-right"].join(" ")}></div>
								</NavLink>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
};

export default CartSidebar;
