import { FaTelegramPlane, FaWhatsapp, FaInstagram } from "react-icons/fa";

const PHONE = [
  {
    formatted: "+7 777 393 80 22",
    clean: "77773938022",
  },
  {
    formatted: "+7 771 425 75 95",
    clean: "77714257595",
  },
];
const EMAIL = "shop@g-factory.kz";
const WHATSAPP = "77477010606";
const PARTNERS = [
  {
    key: "st",
    logo: "../../assets/images/partners/st.svg",
    name: "Swiss Time",
    href: "https://swisstime.kz",
  },
  {
    key: "vv",
    logo: "../../assets/images/partners/vv.svg",
    name: "Ветер Времени",
    href: "https://vetervremeni.kz",
  },
  {
    key: "wo",
    logo: "../../assets/images/partners/wo.svg",
    name: "Watches Outlet",
    href: "https://watchesoutlet.kz",
  },
];
const SOCIAL = [
  {
    Icon: FaInstagram,
    name: "Instagram",
    link: "https://www.instagram.com/gfactory.kz/",
  },
  {
    Icon: FaWhatsapp,
    name: "WhatsApp",
    link: "https://wa.me/+77773938022",
  },
  {
    // iconR: FaTelegramPlane,
    Icon: FaTelegramPlane,
    name: "Telegram",
    link: "https://t.me/gfactorykz",
  },
];
const ADVANTAGES = [
  {
    icon: "verify",
    text: "Международная гарантия",
  },
  {
    icon: "box",
    text: "Бесплатная доставка",
  },
  {
    icon: "qr",
    text: "Оплата с Kaspi QR",
  },
  {
    icon: "kaspi",
    text: "Мы в Kaspi магазине",
  },
];
const ADDRESS = "Алматы, ул. Муканова 70\n ниже ул. Гоголя\n Пн-Вс 10:00-20:00";
const COORDS = [43.2594635745305, 76.91465299999994];
const INFO_LINKS = [
  {
    icon: "box",
    link: "/delivery",
    text: "Доставка и оплата",
  },
  {
    icon: "option",
    link: "/service",
    text: "Сервис центр",
  },
  {
    icon: "help",
    link: "/faq",
    text: "Вопросы и ответы",
  },
];
const FILTER_GROUPS = [
  {
    name: "Основное",
    items: [470294500],
  },
  {
    name: "Механизм",
    items: [943003704, 1450104841],
  },
  {
    name: "Циферблат",
    items: [75691548, 327485703],
  },
  {
    name: "Корпус и ремешок",
    items: [23507374, 1954067787, 262940452, 345162040, 575783779, 365401412],
  },
  {
    name: "Дополнительно",
    items: [1192025089],
  },
];
const COLORS = {
  синий: "#0000ff",
  голубой: "#42aaff",
  мультиколор: "linear-gradient(to right, #fc00ff, #00dbde)",
  коричневый: "#964b00",
  розовый: "#ffc0cb",
  серый: "#808080",
  серебристый: "linear-gradient(to right, #bcc6cc, #eee)",
  зеленый: "#008000",
  черный: "#000",
  белый: "#fff",
  желтый: "#ffff00",
  красный: "#ff0000",
  оранжевый: "#ffa500",
  прозрачный: "rgba(255,255,255,0.1)",
  "red gloss": "#ff2400",
  "yachting blue": "#669bb4",
  "blue matte": "#5294ff",
  "guards red": "#f80000",
  "ice blue": "#d7fffe",
  "ultra violet": "#5f4b8b",
  "black gloss": "#00000f",
  black: "#000",
  "lime green": "#00ff00",
  "navy blue": "#000080",
  "oriental red": "#a62935",
  green: "#008000",
  "brilliance blue": "#0075b3",
  "blue gloss": "#3333ff",
  "night blue": "#252850",
  "matte black": "#000",
  "green/green": "#008000",
  "orient red": "#a63437",
  brown: "#964b00",
  "green/beige": "#19c56a",
  "carmona red": "#f80000",
  blue: "#0000ff",
  "creme white": "#E9E0D2",
  "granite brown": "#413127",
  titanium: "#878681",
  silver: "linear-gradient(to right, #bcc6cc, #eee)",
  аметист: "#9966cc",
  бронзовый: "#cd7f32",
  золотистый: "#ffd700",
  бордовый: "#9b2d30",
  камень: "#ada587",
  фуксия: "#f754e1",
  карбоновый:
    "linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32))",
  бежевый: "#f5f5dc",
  пурпурный: "#800080",
  камуфляж: "linear-gradient(to bottom, #78866B 0%, #69775C 100%)",
  хром: "linear-gradient(to right, #bcc6cc, #eee)",
  серебро: "linear-gradient(to right, #bcc6cc, #eee)",
  зебра: "repeating-linear-gradient(-45deg, #fff 0 4px, black 4px 8px)",
  тигровый: "repeating-linear-gradient(-45deg, #ff9900 0 4px, black 4px 8px)",
  леопардовый:
    "repeating-linear-gradient(-45deg, #f3a752 0 4px, black 4px 8px)",
  палладий: "linear-gradient(to right, #bcc6cc, #eee)",
  фиолетовый: "#8b00ff",
  жемчужный: "#eae0c8",
  бирюзовый: "#30d5c8",
  коралловый: "#ff7f50",
  многоцветный: "linear-gradient(to right, #fc00ff, #00dbde)",
  стальной: "linear-gradient(to right, #bcc6cc, #eee)",
  "черный, белый":
    "repeating-linear-gradient(-45deg, #fff 0 4px, black 4px 8px)",
  "черный, коричневый":
    "repeating-linear-gradient(-45deg, #964b00 0 4px, black 4px 8px)",
  металлический: "linear-gradient(to right, #bcc6cc, #eee)",
};
const RAFFLEDATE = "1.4";

const watchAttributes = {
  gender: {
    title: "Пол",
    info: `
      <p>Пол пользователя, для которого предназначены часы.</p>
      <p>Примеры:
        <ul>
          <li>Мужские часы: предназначены для мужчин.</li>
          <li>Женские часы: предназначены для женщин.</li>
          <li>Унисекс: подходят для обоих полов.</li>
        </ul>
      </p>
    `,
  },
  mechanismType: {
    title: "Тип механизма",
    info: `
      <p>Механизм, используемый в часах для измерения времени.</p>
      <p>Примеры:
        <ul>
          <li>Кварцевый механизм: использует кварцевый кристалл для точного измерения времени.</li>
          <li>Механический механизм: работает на основе пружин и шестерёнок.</li>
          <li>Солнечная батарея: питается от солнечного света.</li>
          <!-- Добавьте другие примеры, если необходимо -->
        </ul>
      </p>
    `,
  },
  powerSource: {
    title: "Источник питания",
    info: `
      <p>Применяемые типы источников питания:</p>
      <ul>
        <li>- Батарейки;</li>
        <li>- Солнечные батареи;</li>
        <li>- Кинетический ротор заряжающий аккумулятор, встроенный в кварцевый механизм;</li>
        <li>- Механический завод пружины, с автоподзаводом или без него.</li>
      </ul>
      <p>Батарейки для кварцевых аксессуаров меняют один раз в 2-3 года. Солнечные батареи служат долгий срок, так как получают энергию от дневного света.</p>
      <p>Аккумуляторы (кинетик-ротор) – принцип работы аналогичен автоподзаводу в механических часах, но вращаясь, ротор, не взводит механическую пружину, а вырабатывает электроэнергию, подзаряжая аккумулятор.</p>
      <p>Механические аксессуары получают энергию от завода пружины с помощью головки. Она находится на торце корпуса. Многие модели оснащены автоподзаводом, то есть механизм подзаводится от движения рук.</p>
    `,
  },
  dialType: {
    title: "Тип циферблата",
    info: `
      <p>Стиль и оформление циферблата часов.</p>
      <p>Примеры:
        <ul>
          <li>Аналоговый циферблат: с классическими стрелками для указания времени.</li>
          <li>Цифровой циферблат: с использованием цифр для отображения времени.</li>
          <li>Комбинированный циферблат: совмещает аналоговый и цифровой форматы.</li>
          <!-- Добавьте другие примеры, если необходимо -->
        </ul>
      </p>
    `,
  },
  backlight: {
    title: "Подсветка",
    info: `
      <p>Способ подсветки циферблата часов.</p>
      <ul>
        <li><strong>Люминесцентное покрытие:</strong> Вариант, применяемый в стрелочных циферблатах: на стрелки, а иногда и на шкалу наносится «фосфорное» покрытие, светящееся в темноте. Такой способ подсветки обходится недорого и может применяться даже в бюджетных моделях; кроме того, он не требует батареек и подходит даже для механических часов. С другой стороны, покрытие часто оказывается не столь эффективно, как этого хотелось бы. Для работы оно должно «зарядиться» от солнца или другого яркого источника света, а хватает этого «заряда» в среднем часов на 5 – 6, причем уже в первые пару часов яркость свечения заметно падает.</li>
        <li><strong>Электронная:</strong> Подсветка на основе светодиодов или других миниатюрных источников света. Как правило, не работает постоянно, а включается и выключается по нажатию специальной кнопки; исключением являются светодиодные циферблаты. Главным достоинством электронной подсветки является эффективность: ее гарантированно хватает для просмотра изображения на циферблате. Кроме того, такие системы не зависят от внешнего освещения, в отличие от описанных выше люминесцентных покрытий. С другой стороны, для работы освещения требуется батарейка; поэтому данный тип подсветки встречается исключительно в кварцевых часах.</li>
        <li><strong>Люминесцентное покрытие/электронная:</strong> Системы, совмещающие оба описанных выше вида подсветки. Данный вариант встречается в основном среди моделей с комбинированными циферблатами: на стрелки наносится светящееся покрытие, а электронная подсветка предназначена в основном для дисплеев и дополнительных шкал (а также как запасной вариант для стрелок). Комбинированная подсветка очень удобна, однако и стоят такие часы несколько дороже.</li>
      </ul>
    `,
  },
  caseShape: {
    title: "Форма корпуса",
    info: `
      <p>Форма корпуса часов чаще всего такую же форму имеет и циферблат, однако встречаются и исключения (например, прямоугольный дисплей в круглом корпусе).</p>
      <p>Этот параметр практически не влияет на функционал, так как выбор в данном случае связан исключительно с тем, насколько удобен и приятен Вам тот или иной вариант. А также выбор формы может пригодиться, если часы выбираются как модный аксессуар, играющий определенную роль в создаваемом образе.</p>
      <p>Что касается конкретных вариантов, то наибольшее распространение в наше время получили:</p>
      <ul>
        <li>- Круглые корпуса — они аккуратно смотрятся и подходят для часов практически любого стиля.</li>
        <li>- Прямоугольные корпуса — довольно популярны и могут относиться к разным стилям.</li>
        <li>- Квадратные корпуса — являются разновидностью прямоугольной формы.</li>
        <li>- Многоугольные, бочкообразные и другие виды корпусов, а также изделия в нестандартном дизайнерском исполнении.</li>
      </ul>
    `,
  },
  caseMaterial: {
    title: "Материал корпуса",
    info: `
      <p>— Нержавеющая сталь. Недорогой и в то же время весьма практичный материал, встречающийся в часах всех ценовых категорий. «Нержавейка» неплохо смотрится, отлично противостоит коррозии и царапинам, долго сохраняет товарный вид и не вызывает аллергии. Правда, она имеет довольно большой вес, но для многих пользователей массивность часов является достоинством, а не недостатком.</p>
      <p>— Титан. Материал премиум-класса, применяемый часах соответствующего ценового уровня. Титановые сплавы сочетают легкость и высокую прочность, кроме того, менее теплопроводны, чем сталь, за счет чего не «холодят» руку. Они практически не подвержены коррозии, не требуют защитных покрытий и являются гипоаллергенными. С другой стороны, подобные корпуса довольно чувствительны к царапинам.</p>
      <p>— Пластик. Общими особенностями всех видов пластика являются простота в обработке, сравнительно невысокая стоимость, небольшой вес, гипоаллергенность, влагостойкость и слабая теплопроводность. По ряду причин этот материал считается оптимальным прежде всего для спортивных часов, в том числе «дайверских» (влагозащищенных). При этом стоит отметить, что в современных часах используется множество сортов пластика — в т. ч. высокопрочные разновидности, по надежности не уступающие стали. Поэтому данный материал встречается во всех ценовых категориях, от бюджетных до топовых; качество корпуса обычно напрямую связано со стоимостью часов.</p>
      <p>— Алюминий. «Часовой металл», считающийся чуть более продвинутым, чем нержавеющая сталь. Алюминиевый сплав также прочен, устойчив к коррозии и царапинам, к тому же имеет солидный внешний вид. При этом он, с одной стороны, заметно легче, с другой — дороже.</p>
      <p>— Карбон. Композитный материал, по сути — высококачественный пластик: нити углеродного волокна, залитые полимерным наполнителем. Карбон примечателен сочетанием небольшого веса с очень высокой прочностью, в целом по надежности он превосходит даже сталь; при этом он имеет многие достоинства пластика, как то: влагостойкость и низкую теплопроводность. Недостатком подобных корпусов, помимо высокой стоимости, является чувствительность к точечным ударам.</p>
    `,
  },
  caseColor: {
    title: "Цвет корпуса",
    info: `
      <p>Современные часы довольно разнообразны по вариантам расцветки. Наибольшее распространение получили модели в оттенках «нержавеющая сталь», золотистом и черном; однако, помимо этого, в продаже можно встретить, в частности, белые, желтые, зеленые, коричневые, красные, оранжевые, розовые, серебристые, серые, синие и фиолетовые корпуса.</p>
      <p>В целом, цвет корпуса — достаточно очевидный параметр; отметим лишь некоторые нюансы, связанные с маркировкой:</p>
      <ul>
        <li>К разноцветным относятся модели, в которых нет четко выраженного основного цвета. Если такой цвет есть — в характеристиках указывается именно он. А для двухцветных корпусов при этом может уточняться дополнительный цвет, например, «нержавеющая сталь с золотистым» или «черный с синим».</li>
        <li>Серебристыми, помимо прочего, считаются пластиковые корпуса без дополнительной окраски.</li>
        <li>«Нержавеющая сталь» в данном случае — это цвет, а не материал; такой оттенок могут иметь изделия из других металлов (например, алюминия и титана), не дополненные покрытием PVD (см. «Покрытие корпуса»). А корпуса золотых часов, в свою очередь, не обязательно делаются именно золотистыми — в ювелирном деле используются также красное и белое золото.</li>
      </ul>
    `,
  },
  strapColor: {
    title: "Цвет ремешка/браслета",
    info: `
      <p>Цвет материала, из которого изготовлен ремешок или браслет часов.</p>
      <p>Примеры:
        <ul>
          <li>Черный: стильный и универсальный выбор.</li>
          <li>Коричневый: традиционный цвет, который хорошо сочетается с классическим стилем.</li>
          <li>Металлический: для браслетов из нержавеющей стали или других металлических материалов.</li>
          <!-- Добавьте другие примеры, если необходимо -->
        </ul>
      </p>
      <p>Выбор цвета ремешка/браслета может влиять на общий внешний вид часов и сочетаться с вашим стилем.</p>
    `,
  },
  glass: {
    title: "Стекло",
    info: `
      <p>Прозрачное покрытие бывает следующих видов:</p>
      <ul>
        <li><strong>Сапфировое:</strong> Стекло из искусственно выращенного сапфира (прозрачного корунда — оксида алюминия). Главным преимуществом этого материала является высочайшая стойкость к царапинам.</li>
        <li><strong>Минеральное:</strong> Обычное стекло, высокая твёрдость, не мутнеет и сохраняет прозрачность. Однако хрупкость может привести к разлетанию на осколки при сильных ударах.</li>
        <li><strong>Пластик:</strong> Простой вариант, используется в моделях начального и среднего ценового диапазона, преимущественно спортивно-туристической специализации. Обладает небольшим весом, низкой стоимостью, и хорошо переносит удары и падения.</li>
      </ul>
      <p>Пластиковое стекло чувствительно к царапинам и может мутнеть от них. Однако, его свойства делают его незаменимым для ударозащищённых часов.</p>
    `,
  },
  strapMaterial: {
    title: "Материал ремешка/браслета",
    info: `
      <p>Материал, из которого изготовлен браслет или ремешок часов.</p>
      <p>Примеры:</p>
      <ul>
        <li>Тканевый ремешок: устойчив к влаге, гибкий, легко регулируется по размеру.</li>
        <li>Каучуковый ремешок: износостойкий, влагоустойчив, популярен в спортивных часах.</li>
        <li>Кожаный ремешок: элегантный, прочный, устойчив к влаге (при правильном уходе).</li>
        <li>Кожзаменитель: более доступная альтернатива коже, но менее прочный.</li>
        <li>Браслет из нержавеющей стали: прочный, долговечный, солидный внешний вид.</li>
        <li>Миланский браслет: металлическое плетение, оригинальный внешний вид, но может «закусывать» волосы.</li>
        <li>Браслет из титана: прочный, легкий, дорогой, встречается в часах премиум-класса.</li>
        <li>Браслет полимер: из гибкого пластика, часто сочетается с корпусами из схожих материалов.</li>
      </ul>
      <p>Выбор материала браслета/ремешка влияет на общий стиль часов и их функциональность.</p>
    `,
  },
};
const watchFunctions = {
  alarm: {
    title: "Будильник",
    info: `<p><strong>Классический будильник</strong> — звуковой сигнал, срабатывающий в заданное пользователем
время. Может использоваться не только для подъема, но и в качестве напоминания о том
или ином событии. Отметим, что в механических моделях (см. «Тип механизма») данная
функция почти не встречается.</p>
`,
  },

  weekDay: {
    title: "День недели",
    info: `<p>Возможность отображения дня недели на циферблате часов. Встречается как в часах с
дисплеями, так и в чисто стрелочных моделях — в них используется механический
индикатор на 7 дней</p>`,
  },

  compass: {
    title: "Компас",
    info: `<p>Традиционный компас — приспособление для определения сторон света. Точность
        встроенных компасов обычно невысока, однако они могут оказаться весьма полезны для
        несложных задач или как крайнее средство при отсутствии других навигационных
        приборов.</p>`,
  },

  worldTime: {
    title: "Мировое время",
    info: `<p>Возможность использовать часы для определения времени в других часовых поясах,
        помимо текущего. Конкретный способ реализации такой функции может быть разным. Так,
        наиболее популярный вариант в стрелочных моделях (см. «Тип циферблата») — наличие
        дополнительного циферблата, на котором устанавливается время интересующего
        часового пояса.</p>
        <p>Такой формат удобен в тех случаях, когда приходится постоянно иметь
        дело с определенным регионом — например, общаться с родственниками или деловыми
        партнерами из конкретной страны.</p>
        <p>Другой вариант — метки на вращающемся безеле (см.
        ниже), соответствующие основным часовым поясам мира; для определения мирового
        времени нужно повернуть безель так, чтобы текущий часовой пояс оказался напротив
        часовой стрелки — тогда остальные метки окажутся напротив тех делений циферблата,
        которые соответствуют времени в этих часовых поясах. Такой формат удобен при частой
        смене часовых поясов — в частности, он встречается в часах для моряков.</p>
        <p>А в моделях с
        цифровыми циферблатами обычно предусматривается переключение экрана между
        текущим и дополнительными часовым поясом (поясами).</p>`,
  },

  stopwatch: {
    title: "Секундомер",
    info: `<p>Традиционный секундомер — функция, позволяющая замерять отрезки времени с
        точностью до секунд, а то и долей секунды. В современных часах можно встретить две
        разновидности секундомеров.</p>
        <p>Первая — это решения, используемые в моделях с
        дисплеями (иными словами, в часах с электронными и комбинированными
        циферблатами). В таких устройствах отсчет секунд отображается на экране и ведется с
        точностью до десятой или даже сотой части секунды; также подобные секундомеры могут
        иметь различные дополнительные функции: отсчет промежуточных результатов по
        контрольным точкам или кругам, сохранение итогов последнего замера и т. п.</p>
        <p>Вторая разновидность секундомеров встречается в стрелочных циферблатах — это
        стрелка на основной оси, которую можно запускать и останавливать по желанию
        пользователя. Это достаточно редкий вариант — по той причине, что чаще всего такая
        стрелка применяется в сочетании с дополнительными шкалами и представляет собой уже
        не секундомер, а хронограф (см. ниже).</p>
        <p>А в часах, где шкал хронографа нет, может
        предусматриваться довольно необычный формат работы подобной стрелки: в обычном
        режиме она работает как стандартная секундная, а при переводе часов в режим
        секундомера — перемещается на ноль и останавливается до начала отсчёта.</p>`,
  },

  timer: {
    title: "Таймер",
    info: `<p>Функция обратного отсчета времени; по окончанию отсчета подается звуковой сигнал. Эта
        функция удобна в тех случаях, когда нужно отмерить строго определенный промежуток
        времени — например, при готовке на кухне: «завести» таймер на нужный промежуток
        намного удобнее, чем считать, на который час нужно выставить будильник.</p>`,
  },

  thermometer: {
    title: "Термометр",
    info: `<p>Термометры в часах обычно предназначаются для измерения температуры окружающего
        воздуха. Такая возможность встречается в основном в продвинутых спортивных моделях.
        Она может пригодиться как для общей оценки окружающей обстановки, так и для
        специфических задач вроде прогнозирования погоды, вычисления текущей высоты и т.п.</p>`,
  },

  timeFormat: {
    title: "Формат времени 12/24",
    info: `<p>Данная особенность указывается исключительно для часов со стрелочными
        циферблатами (и отдельных стрелочно-электронных моделей, см. «Тип циферблата»).
        Она означает, что в дополнение к основной 12-часовой шкале часы имеют еще и
        небольшой циферблат с разметкой на 24 часа.</p>
        <p>Он позволяет определить, какое время
        показывает основная шкала — до или после полудня: например, в 6 часов вечера
        основной циферблат будет указывать на 6 часов, дополнительный — на 18.</p>
        <p>Подобная
        возможность может пригодиться в условиях, когда по окружающей обстановке бывает
        невозможно определить время суток — например, в условиях полярного дня/ночи, или
        при длительном пребывании под землей.</p>
        
        <p>
        Что касается часов с электронными циферблатами, то в них даже 12-часовой формат
дополняется примечанием «AM» или «PM» («до полудня» и «после полудня»
соответственно); к примеру, 20:00 будет соответствовать 8:00 PM. Так что в таких моделях
попросту незачем предусматривать специальные функции для определения времени
суток.

        </p>
        `,
  },

  dayOfMonth: {
    title: "Число месяца",
    info: `<p>Возможность отображения на циферблате часов текущего числа месяца. Это может быть
        как число на дисплее, так и механический указатель в виде окошечка с числом. Отметим,
        что в последнем случае требуется регулярная подстройка календаря: механический
        указатель рассчитан на 31 день, и если в прошедшем месяце меньше 31 дня,
        отображаемую дату придется «прокрутить» вперед.</p>`,
  },

  waterResistance: {
    title: "Водонепроницаемость WR100",
    info: `<p>Водозащита.
        Как следует из названия, данный параметр определяет степень устойчивости часов к
        влаге. Он традиционно указывается как максимальная глубина под водой, на которой
        часы способны сохранять герметичность; однако нужно учитывать, что это число является
        весьма условным. Дело в том, что при расчётах максимальной глубины погружения
        учитывается только статическое давление воды, то есть давление при полной
        неподвижности; в то же время любое движение создаёт динамическое давление, которое
        значительно увеличивает нагрузки на корпус. На практике это означает, что отметка, к
        примеру, в 50 м не означает, что с часами можно нырять на глубину 50 м.
        Современные маркировки по водозащите соответствуют таким практическим
        возможностям:
        — 30 м. (то же что и «Water Resistant» без каких-либо цифр). Начальный уровень,
        допускается попадание капель воды, но не сильных брызг. К примеру, такие часы можно
        не снимать под дождём или при умывании, однако во время душа или мытья авто их
        нужно оставить в безопасном месте. О погружении в воду речи, разумеется, не идёт.
        — 50 м. Минимальная водозащита, допускающая погружение часов в воду. В подобных
        часах обычно можно плавать, а в некоторых случаях — даже прыгать в воду (но не с
        вышек), однако для более серьёзных задач вроде серфинга, ныряния с трубкой или
        аквалангом они не подходят. Как правило, не допускают использования кнопок под водой.
        — 100 м. Такая степень водозащиты позволяет не только плавать, но и нырять на
        небольшие глубины, однако для сёрфинга, прыжков с вышек и погружений с аквалангом
        считается всё же недостаточной.
        — 200 м. Часы с подобной водозащитой относятся к «дайверским»; моделям и могут
        переносить погружения с аквалангом на глубину вплоть до 20 м.
        Отметим, что описанные характеристики являются приблизительными. Конкретные же
        показатели водостойкости и возможности применения часов вместе с водой стоит
        уточнять по официальным данным производителя.</p>`,
  },

  bluetooth: {
    title: "Bluetooth",
    info: `<p>Bluetooth.
        Беспроводной интерфейс Bluetooth предназначен для связи между различными
        электронными устройствами на относительно небольших расстояниях — до 10 м. В
        наручных часах он используется преимущественно для связи со смартфоном или другим
        гаджетом. Функционал такой связи может быть разным, однако в любом случае он
        намного уже, чем в смарт-часах. Из самых популярных возможностей можно назвать
        вывод на часы уведомлений из Facebook и/или Twitter, применение часов в роли пульта
        ДУ для музыки или видео, а также настройку мирового времени (определение часового
        пояса по данным с внешнего устройства).</p>`,
  },

  chronograph: {
    title: "Хронограф",
    info: `<p>Хронограф.
        Аналог секундомера, применяемый в часах со стрелочными циферблатами (см. «Тип
        циферблата»). Подобные часы имеют несколько дополнительных шкал небольшого
        размера, каждая из которых выполняет свою функцию. Например, одна из таких шкал
        может отвечать за отсчет минут (до получаса), вторая — за отсчет часов (до 12 ч), на
        третьей находится обычная секундная стрелка, а стрелка для отсчета секунд
        располагается на центральной оси и остается неподвижной до включения хронографа.</p>`,
  },
};
export {
  PHONE,
  EMAIL,
  WHATSAPP,
  SOCIAL,
  ADVANTAGES,
  ADDRESS,
  INFO_LINKS,
  COORDS,
  FILTER_GROUPS,
  COLORS,
  PARTNERS,
  RAFFLEDATE,
  watchAttributes,
  watchFunctions,
};
