import c from "./styles.module.scss";
import Container from "../container";

import baby_g_mobile from "../../assets/images/banners/baby_g_mobile.png";
import baby_g_web from "../../assets/images/banners/baby_g_web.png";
import g_shock_mobile from "../../assets/images/banners/g_shock_mobile.png";
import g_shock_web from "../../assets/images/banners/g_shock_web.png";
import edifice_mobile from "../../assets/images/banners/edifice_mobile.png";
import edifice_web from "../../assets/images/banners/edifice_web.png";
import pro_trek_mobile from "../../assets/images/banners/pro_trek_mobile.png";
import pro_trek_web from "../../assets/images/banners/pro_trek_web.png";
import sheen_mobile from "../../assets/images/banners/sheen_mobile.png";
import sheen_web from "../../assets/images/banners/sheen_web.png";
import general_mobile from "../../assets/images/banners/general_mobile.png";
import general_web from "../../assets/images/banners/general_web.png";

import { useState } from "react";
import Button from "../UI/button";
import { NavLink } from "react-router-dom";
import Tag from "../UI/tag";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import { useRef } from "react";
import { useEffect } from "react";


const Slide = ({ data }) => {
  
  const {
    theme = "light",
    tag,
    title,
    text,
    btn,
    link,
    image,
    banner = false,
  } = data;
  const options = {
    classes: [c.slide, theme],
    inner: "",
    image: "",
    node: "div",
  };

  if (image && !banner) {
    options.image = (
      <div
        className={c.image}
        style={{ backgroundImage: `url(${image})` }}
      ></div>
    );
  }
  if (image && banner) {
    options.image = (
      <div
        className={c.banner}
        style={{ backgroundImage: `url(${image})` }}
      ></div>
    );
  }

  options.inner = (
    <>
      {tag ? <Tag text={tag} size="small" /> : null}
      {title ? <div className={c.title}>{title}</div> : null}
      {text ? <div className={c.text}>{text}</div> : null}
      {btn ? <div className={c.btn}>{btn}</div> : null}
    </>
  );

  if (link) {
    return (
      <NavLink to={link} end className={options.classes.join(" ")}>
        {options.image}
        <div className={c.content}>{options.inner}</div>
      </NavLink>
    );
  } else {
    return (
      <div className={options.classes.join(" ")}>
        {options.image}
        <div className={c.content}>{options.inner}</div>
      </div>
    );
  }
};

const MainSlider = () => {
  	const prevBtn = useRef(null);
		const nextBtn = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [data] = useState([

    {
      theme: "dark",
      tag: "Лимитированное издание",
      title: "G-Shock",
      btn: <Button text="Подробнее" afterIcon="arrow-right" />,
      link: "/products?filters=1123632576%3A1655608949",
      image: !isMobile ? g_shock_web : g_shock_mobile,
    },

    {
      theme: "dark",
      tag: "Новинка",
      title: "General",
      btn: <Button text="Подробнее" afterIcon="arrow-right" />,
      link: "/products?filters=1123632576%3A1673978871",
      image: !isMobile ? general_web : general_mobile,
    },
    {
      theme: "dark",
      tag: "Новинка",
      title: "Pro-Trek",
      // text: "Новая лимитированная коллекция",
      btn: <Button text="Подробнее" afterIcon="arrow-right" />,
      link: "/products?filters=1123632576%3A503817821",
      image: !isMobile ? pro_trek_web : pro_trek_mobile
    },
    {
      theme: "dark",
      tag: "Новинка",
      title: "Sheen",
      // text: "Новая лимитированная коллекция",
      btn: <Button text="Подробнее" afterIcon="arrow-right" />,
      link: "/products?filters=1123632576%3A391419574",
      image: !isMobile ? sheen_web : sheen_mobile
    },

    {
      theme: "dark",
      tag: "Новинка",
      // tag: 'Лимитированное издание',
      title: "Baby-G",
      // text: "Новая лимитированная коллекция",
      btn: <Button text="Подробнее" afterIcon="arrow-right" />,
      link: "/products?filters=1123632576%3A1244322860",
      image: !isMobile ? baby_g_web : baby_g_mobile
    },
    {
      theme: "dark",
      tag: "Новинка",
      title: "Edifice",
      // text: "Новая лимитированная коллекция",
      btn: <Button text="Подробнее" afterIcon="arrow-right" />,
      link: "/products?filters=1123632576%3A919493840",
      image: !isMobile ? edifice_web : edifice_mobile,
    },
  ]);
  	
  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  	useEffect(() => {
		if (prevBtn.current && nextBtn.current) {
		
		}
	}, [prevBtn, nextBtn]);
  return (
		<section className={c.section}>
			<Container>
				<div className={c.inner}>
					<div className={[c.sliderNav, "dark"].join(" ")}>
						<Button
							ref={prevBtn}
							btnStyle="blur"
							icon="arrow-left"
							size="smaller"
						/>
						<Button
							ref={nextBtn}
							btnStyle="blur"
							icon="arrow-right"
							size="smaller"
						/>
					</div>
					<Swiper
						autoplay={{ delay: 5000, disableOnInteraction: false }}
						modules={[Navigation, Autoplay]}
						spaceBetween={40}
						navigation={{
							prevEl: prevBtn.current,
							nextEl: nextBtn.current,
						
						}}
						onBeforeInit={(swiper) => {
							swiper.params.navigation.prevEl = prevBtn.current;
							swiper.params.navigation.nextEl = nextBtn.current;
						}}
					>
					
						{data.map((item, index) => (
							<SwiperSlide key={index}>
								<Slide data={item} />
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</Container>
		</section>
	);
};

export default MainSlider;
