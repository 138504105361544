import { useState } from "react";
import Input from "../UI/input";
import { useNavigate } from "react-router-dom";
import { CloseMenu } from "../mobileMenu";
import ClickOutside from "../clickOutside";
import c from "./styles.module.scss";
import ProductController from "../../controllers/productController";
import { NavLink } from "react-router-dom";
import { priceFormate } from "../../utils/price";
import { IMAGE_URL} from "../../http";

const ResultsItem = ({ data, onClick }) => {
  const { id, name, price, image } = data;

  return (
    <NavLink end to={"/products/" + id} className={c.item} onClick={onClick}>
      <div className={c.image}>
        {image ? (
          <img src={data.image ? IMAGE_URL + data.image : null} alt={name} />
        ) : (
          <div className={[c.noImage, "icon-image"].join(" ")}></div>
        )}
      </div>
      <div className={c.text}>
        <div className={c.title}>{name}</div>
        <div className={c.meta}>
          <div className={c.price}>{priceFormate(price)}</div>
        </div>
      </div>
    </NavLink>
  );
};

const SearchField = ({ inputStyle = "primary", size = "normal" }) => {
  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);
  const [results, setResults] = useState([]);
  const navigate = useNavigate();

  let timer = null;

  const valueChanged = async (value) => {
    if (timer !== null) {
      clearTimeout(timer);
      timer = null;
    }

    if (value) {
      timer = setTimeout(async () => {
        const getProducts = await ProductController.getAll({
          limit: 5,
          search: value,
          available: true,
        });

        setResults(getProducts.data.rows);
      }, 500);

      setShow(true);
    } else {
      setShow(false);
    }

    setValue(value);
  };

  const onProductClick = () => {
    setValue("");
    setShow(false);
    setResults("");
    CloseMenu();
  };

  return (
    <ClickOutside
      onClick={() => {
        setShow(false);
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          CloseMenu();
          setShow(false);
          navigate(`/search/${value}`);
        }}
        className={c.form}
      >
        <Input
          placeholder="Введите запрос"
          icon="search"
          size={size}
          value={value}
          onChange={(e) => valueChanged(e.target.value)}
          inputStyle={inputStyle}
        />
        <div
          className={[c.results, show && results.length ? c.show : false].join(
            " "
          )}
        >
          {results.length
            ? results
                .filter((item) => item.quantity > 0)
                .map((item) => (
                  <ResultsItem
                    key={item.id}
                    data={item}
                    onClick={onProductClick}
                  />
                ))
            : null}
        </div>
      </form>
    </ClickOutside>
  );
};

export default SearchField;
