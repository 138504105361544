const PagesMenu = [
  {
    url: "/products",
    text: "Каталог",
    type: "text",
  },
  {
    url: "/products?filters=1123632576%3A1673978871",
    text: "GENERAL",
    type: "text",
  },
  {
    url: "/products?filters=470294500%3A1556981024%2C380812251",
    text: "Мужские часы",
    type: "text",
  },
  {
    url: "/products?filters=470294500%3A380812251%2C1331059330",
    text: "Женские часы",
    type: "text",
  },
  {
    url: "/products?filters=1123632576%3A1655608949",
    path: "/g-shock.svg",
    type: "svg",
  },
  {
    url: "/products?filters=1123632576%3A1244322860",
    path: "/baby-g.svg",
    type: "svg",
  },
  {
    url: "/products?filters=1123632576%3A919493840",
    path: "/edifice.svg",
    type: "svg",
  },
  {
    url: "/products?filters=1123632576%3A503817821",
    path: "/protrek.svg",
    type: "svg",
  },
  {
    url: "/products?filters=1123632576%3A265688798",
    path: "/sheen.svg",
    type: "svg",
  },
  {
    url: "/delivery",
    text: "Доставка",
    type: "text",
  },
  {
    url: "/service",
    text: "Сервис центр",
    type: "text",
  },
  {
    url: "/faq",
    text: "FAQ",
    type: "text",
  },
  {
    url: "/contact",
    text: "Контакты",
    type: "text",
  },
  {
    url: "/offer",
    text: "Публичная оферта",
    type: "text",
  },
];

export default PagesMenu;
